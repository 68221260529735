<template>
  <div>
    <finished v-if="completed" />
    <div
      v-else
      class="w-full container p-20 mx-auto sm:flex sm:flex-col sm:justify-center"
    >
      <div
        v-if="showAlert"
        class="alert alert-info"
      >
        <div class="flex-1">
          <svg

            fill="none"
            viewBox="0 0 24 24"
            class="w-6 h-6 mx-2 stroke-current"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <label>Fyll i hela formuläret.</label>
        </div>
      </div>
      <div class="flex items-center">
        <img
          src="@/assets/logos/gi_screening_logo.png"
          class="bg-primary-dark w-24 rounded-md p-2"
        >
        <h1 class="ml-2">
          Gruppskattningsformulär
        </h1>
      </div>
      <p class="my-5">
        Skatta individerna i listan. Börja med dig själv. När du har skattat alla individerna i listan tryck på
        ”skicka in”. Skattningen är anonym. Var så uppriktig
        du kan. Skattningen innehåller två sektioner. Den första avser objektivt uppmätta kognitiva
        förmågor. I den andra sektionen ska du skatta ett antal egenskaper.
      </p>

      <div class="form-control w-56">
        Fyll i din epostadress
        <div class="relative mt-2">
          <input
            v-model="email"
            type="text"
            placeholder="Email"
            class="w-full pr-16 input input-primary input-bordered"
          >
        </div>
      </div>

      <!-- {{ answers }} -->
      <div
        class="flex flex-col py-4"
      >
        <div class="flex space-x-4 items-center">
          <select
            v-model="selectedMember"
            class="select select-bordered w-full max-w-xs"
          >
            <option
              v-for="(member, index) in teamMembers"
              :key="index"
              :value="member"
              :class="{'text-gray-300': answeredAll[member]}"
            >
              <div
                v-if="answeredAll[member]"
                class="text-black"
              >
                ✓
              </div>{{ member }}
            </option>
          </select>
          <h3 class="text-2xl font-bold">
            {{ selectedMember }}
          </h3>
        </div>
        <div class="divider" />
        <h3 class="text-2xl font-bold mt-5">
          Kognitiva förmågor
        </h3>
        <div class="mt-2">
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-7">
            <ability
              v-for="(ability, index1) in CogntiveAbilities"
              :key="index1"
              v-model="answers[selectedMember][ability.id]"
              :ability="ability.title"
            />
          </div>
        </div>
        <!-- <div class="divider" />  -->
        <h3 class="text-2xl font-bold mt-10">
          Egenskaper
        </h3>

        <div class="space-x-5 mt-2">
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-7">
            <ability
              v-for="(ability, index1) in traits"
              :key="index1"
              v-model="answers[selectedMember][ability.id]"
              :ability="ability.title"
            />
          </div>
        </div>
        <div class="flex flex-col items-center mt-4">
          <button @click="fillAll">
            TEST
          </button>
          <button
            v-if="answeredAll"
            class="btn w-96"
            @click="submit"
          >
            Skicka in
          </button>
          <span
            v-if="showAlert"
            class="text-red-700"
          >* Fyll i hela formuläret, titta i listan vilka individer du har kvar. </span>
          <span
            v-if="showEmailAlert"
            class="text-red-700"
          >* Vänligen fyll i din epostadress innan du skickar in formuläret. </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Ability from '../components/Ability.vue'
import Finished from '../components/Finished.vue'
export default {
  components: {
    // RadioInput,
    Ability,
    Finished
  },
  data () {
    return {
      showAlert: false,
      teamMembers: [
        'Mathias Landström',
        'Simon Risberg',
        'Wiliam Sonnerklev',
        'Linus Olofsson',
        'My Björk'
      ],
      selectedMember: 'Mathias Landström',
      abilities1: [
        {
          title: 'Uppmärksamhet',
          id: 'at'
        },
        {
          title: 'Avsökning',
          id: 'sc'
        },
        {
          title: 'Processhastighet',
          id: 'ps'
        },
        {
          title: 'Korttidsminne',
          id: 'st'
        },
        {
          title: 'Arbetsminne',
          id: 'wm'
        },
        {
          title: 'Kreativitet',
          id: 'ct'
        },
        {
          title: 'Kognitiv Flexibilitet',
          id: 'cf'
        }
      ],
      abilities2: [
        {
          title: 'Multiprocessande',
          id: 'mt'
        },
        {
          title: 'Impulskontroll',
          id: 'ic'
        },
        {
          title: 'Konceptualisering',
          id: 'kc'
        },
        {
          title: 'Rationellt tänkande',
          id: 'rt'
        },
        {
          title: 'Strategiskt tänkande',
          id: 'sp'
        }
      ],
      abilities3: [
        {
          title: 'Ledarskap',
          id: 'ls'
        },
        {
          title: 'Målinriktning',
          id: 'go'
        },
        {
          title: 'Anpassningsförmåga',
          id: 'ac'
        }, {
          title: 'Teambuilding',
          id: 'tb'
        },
        {
          title: 'Kommunikation',
          id: 'cc'
        },
        {
          title: 'Attityd / Engagemang',
          id: 'ae'
        }
      ],
      abilities4: [
        {
          title: 'Ansvarstagande',
          id: 'rb'
        },
        {
          title: 'Serviceinriktad',
          id: 'sm'
        },
        {
          title: 'Självständighet',
          id: 'am'
        }, {
          title: 'Noggrannhet',
          id: 'dt'
        }, {
          title: 'Initiativtagande',
          id: 'it'
        },
        {
          title: 'Samarbetsvilja',
          id: 'tp'
        }
      ],
      abilities5: [
        {
          title: 'Ambition',
          id: 'ab'
        },
        {
          title: 'Problemlösning',
          id: 'pl'
        },
        {
          title: 'Stresstålighet',
          id: 'ts'
        }
      ],
      answers: {
        'Mathias Landström': {},
        'Simon Risberg': {},
        'Wiliam Sonnerklev': {},
        'Linus Olofsson': {},
        'My Björk': {}
      },
      completed: false,
      email: '',
      showEmailAlert: false
    }
  },
  computed: {
    answeredAll () {
      const x = {}
      for (const member in this.answers) {
        if (Object.keys(this.answers[member]).length === [...this.abilities1, ...this.abilities2, ...this.abilities3, ...this.abilities4, ...this.abilities5].length) x[member] = true
      }
      return x
    },
    CogntiveAbilities () {
      return [...this.abilities1, ...this.abilities2]
    },
    traits () {
      return [...this.abilities3, ...this.abilities4, ...this.abilities5]
    }
  },
  mounted () {
    // this.fillAll()
  },
  methods: {
    fillAll () {
      const answers1 = {
        'Mathias Landström': {},
        'Simon Risberg': {},
        'Wiliam Sonnerklev': {},
        'Linus Olofsson': {},
        'My Björk': {}
      }
      for (const member in this.answers) {
        const allAbilities = [...this.abilities1, ...this.abilities2, ...this.abilities3, ...this.abilities4, ...this.abilities5]
        allAbilities.forEach(el => {
          answers1[member][el.id] = 3
        })
      }
      this.answers = answers1
    },
    async submit () {
      if (Object.keys(this.answeredAll).length === this.teamMembers.length) {
        if (this.email === '') {
          this.showEmailAlert = true
          return
        }
        await this.$api.createTeamRatingResult(this.answers, this.email)
        this.completed = true
      } else {
        this.showAlert = true
        setTimeout(() => {
          this.showAlert = false
        }, 1000 * 10)
      }
    }
  }
}
</script>

<style>
</style>
