<template>
  <div
    class="flex flex-col space-y-2 mt-5 w-full"
    :class="{'border-2': highlight, 'border-red-400': highlight, 'opacity-60': value != null}"
  >
    <div class="text-lg font-bold text-center">
      {{ ability }}
    </div>
    <div class="flex">
      <radio-input
        v-for="(x, index2) in stanine"
        :key="index2"

        :scale="x"
      >
        <input
          :value="x"
          :checked="x === value"
          type="radio"
          class="radio radio-accent"
          @input="$emit('input', x)"
        >
      </radio-input>
    </div>
  </div>
</template>

<script>
import RadioInput from './RadioInput.vue'
export default {
  components: {
    RadioInput
  },
  props: ['index', 'value', 'ability', 'highlight'],
  data () {
    return {
      stanine: [
        1, 2, 3, 4, 5, 6, 7, 8, 9
      ]
    }
  }
}
</script>

<style>
.color1 {
  background-color: #F3F7FB;
}
.color2 {
  background-color: #F8FBFD;
}
</style>
