<template>
  <div class="form-control flex-grow ">
    <label
      class="cursor-pointer label flex flex-col"
    >

      <span class="label-text text-center text-lg text-primary-dark">{{ scale }}</span>
      <slot />

    </label>
  </div>
</template>

<script>
export default {
  props: ['scale', 'scaleBetween', 'last']
}
</script>

<style>
</style>
