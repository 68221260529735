<template>
  <div class="hero min-h-screen bg-base-200">
    <div class="text-center hero-content">
      <div class="max-w-md">
        <h1 class="mb-5">
          <span>Tack så mycket!</span>
        </h1>
        <p class="mb-5">
          <span>Ditt svar har skickats.</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style>
</style>
